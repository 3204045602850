import { Link } from "@StarberryUtils";
import React from "react"
import { Container, Row, Col, Fade } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
// import Agent01 from "../../../images/static/agent.png"
// import Agent02 from "../../../images/static/agent02.png"
import OurTeamSidebar from "../../Sidebar/OurTeamSidebar"
import IncludedSidebar from "../../Sidebar/IncludedSidebar"
import { GetURL } from "../../Common/site/functions"

// Style
import './AboutSection.scss';

import { inViewOptions, containerVariants, fadeInFromBottom, fadeInFromLeft } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const AboutSection = (props) => {
  let url = ''
  if (props.CTA_Link) {
    url = GetURL(props.CTA_Link.id)
  } 
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="about-section guide-details overflow-hidden"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Container>
            <Row>
              <Col xl={props.Sidebar ? 8 : 12} lg={props.Sidebar ? 7 : 12}>
                <motion.div className="about-heading" variants={fadeInFromBottom}>
                  {props.layout === "Blank_Page" ?
                    <h1>{props.Intro_Title}</h1>
                    : <span>{props.Intro_Title}</span>
                  }
                  {props.Intro_Content &&
                    HTMLReactParser(props.Intro_Content)
                  }
                  {(props.CTA_Label && url[1] === "Internal") &&
                    <Link to={`/${url[0]}`} className="btn btn-outline">{props.CTA_Label}</Link>
                  }
                  {(props.CTA_Label && url[1] === "External") &&
                    <a href={`${url ? url[0] : ''}`} target="_blank" className="btn btn-outline">{props.CTA_Label}</a>
                  }
                </motion.div>
              </Col>
              {props.Sidebar &&
                <Col xl={4} lg={5}>
                  {props.Sidebar === "Our_Team" &&
                    <motion.div variants={fadeInFromLeft}>
                      <OurTeamSidebar />
                    </motion.div>
                  }
                  {props.Sidebar === "Whats_Included" &&
                    <motion.div variants={fadeInFromLeft}>
                      <IncludedSidebar />
                    </motion.div>
                  }
                </Col>
              }
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  )
}



export default AboutSection