// import { Link } from "gatsby"
import React from "react"
import { Row, Col } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
// import Agent01 from "../../images/static/agent.png"
// import Agent02 from "../../images/static/agent02.png"
import { useStaticQuery, graphql } from "gatsby";
import _ from "lodash"
// Style 
import { fadeInFromRight } from '../Utils/animations';
import { motion } from "framer-motion";

const OurTeamSidebar = (props) => {

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      globalModule { 
        Service_Page_Sidebar_Content  
        Service_Page_Sidebar_Peoples {
          URL
          Designation
          offices{
            Name
          }
          Name
          Phone
          Tile_Image {
            url
            alternativeText
          }
        }
      }
    }
  }
`);

  const content = data.glstrapi.globalModule 
  const officeName = (item) => {
    if (_.toLower(item.Designation) === "branch manager" || _.toLower(item.Designation) === "area manager") {
      return _.join(_.map(item.offices, 'Name'), ' & ');
    }
  }
  return (
    <motion.div className="block-sticky overflow-hidden" variants={fadeInFromRight}>
      <div className="help">
        {content.Service_Page_Sidebar_Content &&
          HTMLReactParser(content.Service_Page_Sidebar_Content.replace(/<br\s*\/?>/gi, ''))
        }
        <Row>
          {content.Service_Page_Sidebar_Peoples && content.Service_Page_Sidebar_Peoples.map((item, i) => {
            return (
              <Col xl={12} lg={12} md={6}>
                <div className="agent-info" key={i}>
                  <div className="agent-img">
                    <img src={item.Tile_Image.url} alt={`${item.Name} - DBRoberts`} />
                  </div>
                  <div className="agent-details">
                    <h3>{item.Name}</h3>
                    <span className="profile">{officeName(item)} {item.Designation}</span>
                    {item.Phone &&
                      <a href={`tel:+44${item.Phone}`} className="tel">{item.Phone}</a>
                    }
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
      </div>
    </motion.div>
  )
}



export default OurTeamSidebar