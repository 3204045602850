// import { Link } from "gatsby"
import React from "react"
// import { Button, Form, Container, Row, Col, Fade } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
// import Agent01 from "../../images/static/agent.png"
// import Agent02 from "../../images/static/agent02.png"
import { useStaticQuery, graphql } from "gatsby";

// Style 
import { fadeInFromRight } from '../Utils/animations';
import { motion } from "framer-motion";

const IncludedSidebar = (props) => {

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      globalModule { 
        Included_Sidebar_Content 
      }
    }
  }
`);

  const content = data.glstrapi.globalModule 

  return (
    <motion.div className="block-sticky overflow-hidden" variants={fadeInFromRight}>
      <div className="help included-sidebar">
        {content.Included_Sidebar_Content &&
          HTMLReactParser(content.Included_Sidebar_Content.replace(/<br\s*\/?>/gi, ''))
        } 
      </div> 
      </motion.div>
  )
}



export default IncludedSidebar